import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/about-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 className="centered-text-small-screen">Juan Moisés Torrijos</h1>
    <div {...{
      "className": "small-image float-left rounded-circle small-shadow centered-small-screen"
    }}>{`  
`}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "105.84795321637426%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGgABAAIDAQAAAAAAAAAAAAAAAAIFAQMEBv/EABcBAQEBAQAAAAAAAAAAAAAAAAIDAQX/2gAMAwEAAhADEAAAAbvRnhLuECHlBPpyG0//xAAaEAACAwEBAAAAAAAAAAAAAAABAgADERAS/9oACAEBAAEFAnPlan0y6xVWq0JAwYbzZ//EABcRAAMBAAAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8BC6Is/8QAFhEBAQEAAAAAAAAAAAAAAAAAEAFB/9oACAECAQE/AdIf/8QAGhAAAwADAQAAAAAAAAAAAAAAAAERAhAgIf/aAAgBAQAGPwJslumm/S52FTvH/8QAGxABAAICAwAAAAAAAAAAAAAAAQARECExUWH/2gAIAQEAAT8hijWHbvB8bmiOCFatlUY8YpdxVqCOGf/aAAwDAQACAAMAAAAQnP8Awv/EABgRAQEBAQEAAAAAAAAAAAAAAAEAIREx/9oACAEDAQE/EGwNZExgdn2//8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQIBAT8QWD//xAAbEAEAAwEBAQEAAAAAAAAAAAABABEhMVFxEP/aAAgBAQABPxD0AMmAKX0P38K8wLbcCwKBK+lHNh1F42RgpVbVewQK0GbA6QO4z//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Juan Moisés Torrijos",
          "title": "Juan Moisés Torrijos",
          "src": "/static/fc89b02ef1e8ee144f73fd5dad20516c/066f9/juan-moises-torrijos-2020-4.jpg",
          "srcSet": ["/static/fc89b02ef1e8ee144f73fd5dad20516c/0b054/juan-moises-torrijos-2020-4.jpg 171w", "/static/fc89b02ef1e8ee144f73fd5dad20516c/ebfc4/juan-moises-torrijos-2020-4.jpg 343w", "/static/fc89b02ef1e8ee144f73fd5dad20516c/066f9/juan-moises-torrijos-2020-4.jpg 400w"],
          "sizes": "(max-width: 400px) 100vw, 400px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </div>
    <p><strong parentName="p">{`Desarrollador Web Full Stack`}</strong>{` con `}<em parentName="p">{`10 años de experiencia`}</em>{` diseñando y desarrollando `}<strong parentName="p">{`aplicaciones web`}</strong>{` desde emprendimientos hasta empresas corporativas. Habilidad comprobada en la `}<strong parentName="p">{`construcción de sitios web`}</strong>{` rápidos y con excelente experiencia de usuario.`}</p>
    <p>{`Dominio en la `}<em parentName="p">{`creación de aplicaciones web`}</em>{` escalables y `}<strong parentName="p">{`calidad de código`}</strong>{` comprobado con `}<strong parentName="p">{`controles de acceso, roles y capacidades`}</strong>{`, `}<strong parentName="p">{`creación de modelos (aplicaciones CRUD)`}</strong>{`, manejo de `}<strong parentName="p">{`base de dato relacionadas SQL o basadas en documentos (NoSQL)`}</strong>{`. Gran capacidad de `}<em parentName="p">{`comunicación`}</em>{`, `}<em parentName="p">{`creatividad`}</em>{` y `}<em parentName="p">{`manejo de problemas`}</em>{` o situaciones complejas.`}</p>
    <h2>{`Expertiz`}</h2>
    <ul>
      <li parentName="ul">{`HTML/CSS`}</li>
      <li parentName="ul">{`JavaScript`}</li>
      <li parentName="ul">{`ReactJS`}</li>
      <li parentName="ul">{`NodeJS`}</li>
      <li parentName="ul">{`VueJS`}</li>
      <li parentName="ul">{`Desarrollo de Temas Personalizados para WordPress`}</li>
      <li parentName="ul">{`Sistema de Control de Versiones GIT`}</li>
      <li parentName="ul">{`Construcción de APIs REST`}</li>
      <li parentName="ul">{`Bases de Datos Relacionadas SQL`}</li>
      <li parentName="ul">{`Bases de Datos orientadas a documentos (NoSQL)`}</li>
      <li parentName="ul">{`Integración Continua/Despliegue Continuo (CI/CD)`}</li>
      <li parentName="ul">{`Pruebas Automatizadas para aplicaciones (Unit Testing, Integration Testing & E2E Testing)`}</li>
      <li parentName="ul">{`Aseguranza de Calidad de Código`}</li>
      <li parentName="ul">{`Terminales UNIX/Linux`}</li>
    </ul>
    <h2>{`Experiencia Profesional`}</h2>
    <p>{`Encargado de Tecnología en `}<a parentName="p" {...{
        "href": "https://intermaritime.org"
      }}>{`InterMaritime`}</a>{` y `}<a parentName="p" {...{
        "href": "https://panamamaritimetraining.com"
      }}>{`Panama Maritime Training Sercices`}</a>{`.`}</p>
    <p>{`Fundador de `}<a parentName="p" {...{
        "href": "https://digiartes.com"
      }}>{`Digiartes`}</a>{` una agencia de diseño y desarrollo de aplicaciones web y móviles.`}</p>
    <Link to="/contactame" mdxType="Link">Contáctame aquí.</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      